import Box from "@mui/material/Box";
import { currencyFormat } from "../../helpers/functions";
import Tooltip from "@mui/material/Tooltip";

/**
 * Returns status of the price
 * @param {object} price - The object that holds the price data.
 * @returns {string} - The status of the price.
 */
export const getPriceStatus = (price) => {
    let status = "default";

    if (price?.discount?.active && price?.rebate?.active) {
        status = "discount_rebates";
    }
    if (price?.discount?.active && !price?.rebate?.active) {
        status = "discount";
    }
    if (price?.rebate?.active && !price?.discount?.active) {
        status = "rebates";
    }

    return status;
};

/**
 * Returns are prices equal
 * @param {object} price - The object that holds the price data.
 * @returns {boolean} - Are prices equal.
 */
export const getArePricesEqual = (price) => {
    return price?.min?.price?.original === price?.max?.price?.original;
};

/**
 * Returns status of the inventory
 * @param {object} inventory - The object that holds the inventory data.
 * @returns {boolean} - The status of the inventory - is in stock or not.
 */
export const checkIsInStock = (inventory) => {
    return inventory?.inventory_defined && inventory?.amount > 0;
};

/**
 * Returns status of the inventory
 * @param {object} price - The object that holds the price data.
 * @returns {object} - The status of the price - is it defined and is it the range of prices.
 */
export const checkPrices = (price) => {
    let data = {};

    data.price_defined = !!(price?.price_defined && price?.price?.original > 0);

    data.price_range = price?.min?.price?.original > 0 && price?.max?.price?.original > 0;

    return data;
};

/**
 * Returns status of the inventory
 * @param {object} data - The object that holds the price data.
 * @returns {JSX} - Default prices, without rebates or discounts.
 */
export const renderDefaultPrices = (data = {}) => {
    let is_range = data?.is_price_range;
    let price = data?.price;

    if (is_range) {
        let are_range_prices_equal = getArePricesEqual(price);
        if (are_range_prices_equal) {
            return <p className={`w-100 p-2 mt-3 bold`}>{currencyFormat(price?.min?.price?.original)}</p>;
        } else {
            return (
                <p className={`w-100 p-2 mt-3 bold`}>
                    {currencyFormat(price?.min?.price?.original)} - {currencyFormat(price?.max?.price?.original)}
                </p>
            );
        }
    } else {
        return <p className={`w-100 p-2 mt-3 bold`}>{currencyFormat(price?.price?.original)}</p>;
    }
};

/**
 * Returns status of the inventory
 * @param {object} data - The object that holds the price data.
 * @returns {JSX} - Prices after discount.
 */
export const renderDiscountPrices = (data = {}) => {
    let is_range = data?.is_price_range;
    let price = data?.price;

    if (is_range) {
        let are_range_prices_equal = getArePricesEqual(price);

        if (are_range_prices_equal) {
            return (
                <Tooltip
                    placement={"top"}
                    arrow={true}
                    title={
                        <Box>
                            <Box>
                                Regularna cena: <span>{currencyFormat(price?.min?.price?.original)}</span>
                            </Box>

                            {price?.discount?.active &&
                                price?.discount?.campaigns?.map(({ calc: { calc_name } }, i) => {
                                    return (
                                        <Box key={i}>
                                            Cena sa popustom ({calc_name}): <span>{currencyFormat(price?.min?.price?.discount)}</span>
                                        </Box>
                                    );
                                })}
                        </Box>
                    }
                >
                    <p
                        className={`w-100 p-2 mt-3 bold`}
                        style={{
                            textDecoration: "underline dotted",
                        }}
                    >
                        {currencyFormat(price?.min?.price?.discount)}
                    </p>
                </Tooltip>
            );
        } else {
            return (
                <Tooltip
                    placement={"top"}
                    arrow={true}
                    title={
                        <Box>
                            <Box>
                                Regularna cena:{" "}
                                <span>
                                    {currencyFormat(price?.min?.price?.original)} - {currencyFormat(price?.max?.price?.original)}
                                </span>
                            </Box>

                            {price?.discount?.active &&
                                price?.discount?.campaigns?.map(({ calc: { calc_name } }, i) => {
                                    return (
                                        <Box key={i}>
                                            Cena sa popustom ({calc_name}):{" "}
                                            <span>
                                                {currencyFormat(price?.min?.price?.discount)} - {currencyFormat(price?.max?.price?.discount)}
                                            </span>
                                        </Box>
                                    );
                                })}
                        </Box>
                    }
                >
                    <p
                        className={`w-100 p-2 mt-3 bold`}
                        style={{
                            textDecoration: "underline dotted",
                        }}
                    >
                        {currencyFormat(price?.min?.price?.discount)} - {currencyFormat(price?.max?.price?.discount)}
                    </p>
                </Tooltip>
            );
        }
    } else {
        return (
            <Tooltip
                placement={"top"}
                arrow={true}
                title={
                    <Box>
                        <Box>
                            Regularna cena: <span>{currencyFormat(price?.price?.original)}</span>
                        </Box>
                        {price?.discount?.active &&
                            price?.discount?.campaigns?.map(({ calc: { calc_name } }, i) => {
                                return (
                                    <Box key={i}>
                                        Cena sa popustom ({calc_name}): <span>{currencyFormat(price?.price?.discount)}</span>
                                    </Box>
                                );
                            })}
                    </Box>
                }
            >
                <p
                    className={`w-100 p-2 mt-3 bold`}
                    style={{
                        textDecoration: "underline dotted",
                    }}
                >
                    {currencyFormat(price?.price?.discount)}
                </p>
            </Tooltip>
        );
    }
};

/**
 * Returns status of the inventory
 * @param {object} data - The object that holds the price data.
 * @returns {JSX} - Prices after rebates.
 */
export const renderRebatePrices = (data = {}) => {
    let is_range = data?.is_price_range;
    let price = data?.price;

    if (is_range) {
        let are_range_prices_equal = getArePricesEqual(price);

        if (are_range_prices_equal) {
            return (
                <Tooltip
                    placement={"top"}
                    arrow={true}
                    title={
                        <Box>
                            <Box>
                                Regularna cena: <span>{currencyFormat(price?.min?.price?.original)}</span>
                            </Box>

                            {price?.rebate?.active &&
                                price?.rebate?.rebates?.map(({ calc: { calc_name } }, i) => {
                                    return (
                                        <Box key={i}>
                                            Cena sa rabatom ({calc_name}): <span>{currencyFormat(price?.min?.price?.discount)}</span>
                                        </Box>
                                    );
                                })}
                        </Box>
                    }
                >
                    <p
                        className={`w-100 p-2 mt-3 bold`}
                        style={{
                            textDecoration: "underline dotted",
                        }}
                    >
                        {currencyFormat(price?.min?.price?.discount)}
                    </p>
                </Tooltip>
            );
        } else {
            return (
                <Tooltip
                    placement={"top"}
                    arrow={true}
                    title={
                        <Box>
                            <Box>
                                Regularna cena:{" "}
                                <span>
                                    {currencyFormat(price?.min?.price?.original)} - {currencyFormat(price?.max?.price?.original)}
                                </span>
                            </Box>

                            {price?.rebate?.active &&
                                price?.rebate?.rebates?.map(({ calc: { calc_name } }, i) => {
                                    return (
                                        <Box key={i}>
                                            Cena sa rabatom ({calc_name}):{" "}
                                            <span>
                                                {currencyFormat(price?.min?.price?.discount)} - {currencyFormat(price?.max?.price?.discount)}
                                            </span>
                                        </Box>
                                    );
                                })}
                        </Box>
                    }
                >
                    <p
                        className={`w-100 p-2 mt-3 bold`}
                        style={{
                            textDecoration: "underline dotted",
                        }}
                    >
                        {currencyFormat(price?.min?.price?.discount)} - {currencyFormat(price?.max?.price?.discount)}
                    </p>
                </Tooltip>
            );
        }
    } else {
        return (
            <Tooltip
                placement={"top"}
                arrow={true}
                title={
                    <Box>
                        <Box>
                            Regularna cena: <span>{currencyFormat(price?.price?.original)}</span>
                        </Box>
                        {price?.rebate?.active &&
                            price?.rebate?.rebates?.map(({ calc: { calc_name } }, i) => {
                                return (
                                    <Box key={i}>
                                        Cena sa rabatom ({calc_name}): <span>{currencyFormat(price?.price?.discount)}</span>
                                    </Box>
                                );
                            })}
                    </Box>
                }
            >
                <p
                    className={`w-100 p-2 mt-3 bold`}
                    style={{
                        textDecoration: "underline dotted",
                    }}
                >
                    {currencyFormat(price?.price?.discount)}
                </p>
            </Tooltip>
        );
    }
};

/**
 * Returns status of the inventory
 * @param {object} data - The object that holds the price data.
 * @returns {JSX} - Prices after rebates and discounts.
 */
export const renderRebateAndDiscountPrices = (data = {}) => {
    let is_range = data?.is_price_range;
    let price = data?.price;

    const calcRebatePrice = (price, rebate_amount) => {
        let amount = rebate_amount ?? 0;
        return Number(price) - Number(amount);
    };

    const calcDiscountPrice = (price, discount_amount) => {
        let amount = discount_amount ?? 0;
        return Number(price) - Number(amount);
    };

    if (is_range) {
        const rebateMinPrice = calcRebatePrice(price?.min?.price?.original, price?.min?.rebate?.amount);
        const rebateMaxPrice = calcRebatePrice(price?.max?.price?.original, price?.max?.rebate?.amount);

        const discountMinPrice = calcDiscountPrice(rebateMinPrice, price?.min?.discount?.amount);
        const discountMaxPrice = calcDiscountPrice(rebateMaxPrice, price?.max?.discount?.amount);

        let are_range_prices_equal = getArePricesEqual(price);

        if (are_range_prices_equal) {
            return (
                <Tooltip
                    placement={"top"}
                    arrow={true}
                    title={
                        <Box>
                            <Box>
                                Regularna cena: <span>{currencyFormat(price?.min?.price?.original)}</span>
                            </Box>

                            {price?.rebate?.active &&
                                price?.rebate?.rebates?.map(({ calc: { calc_name } }, i) => {
                                    return (
                                        <Box key={i}>
                                            Cena sa rabatom ({calc_name}): <span>{currencyFormat(rebateMinPrice)}</span>
                                        </Box>
                                    );
                                })}
                            {price?.discount?.active &&
                                price?.discount?.campaigns?.map(({ calc: { calc_name } }, i) => {
                                    return (
                                        <Box key={i}>
                                            Cena sa popustom ({calc_name}): <span>{currencyFormat(discountMinPrice)}</span>
                                        </Box>
                                    );
                                })}
                        </Box>
                    }
                >
                    <p
                        className={`w-100 p-2 mt-3 bold`}
                        style={{
                            textDecoration: "underline dotted",
                        }}
                    >
                        {currencyFormat(price?.min?.price?.discount)}
                    </p>
                </Tooltip>
            );
        } else {
            return (
                <Tooltip
                    placement={"top"}
                    arrow={true}
                    title={
                        <Box>
                            <Box>
                                Regularna cena:{" "}
                                <span>
                                    {currencyFormat(price?.min?.price?.original)} - {currencyFormat(price?.max?.price?.original)}
                                </span>
                            </Box>

                            {price?.rebate?.active &&
                                price?.rebate?.rebates?.map(({ calc: { calc_name } }, i) => {
                                    return (
                                        <Box key={i}>
                                            Cena sa rabatom ({calc_name}):{" "}
                                            <span>
                                                {currencyFormat(rebateMinPrice)} - {currencyFormat(rebateMaxPrice)}
                                            </span>
                                        </Box>
                                    );
                                })}
                            {price?.discount?.active &&
                                price?.discount?.campaigns?.map(({ calc: { calc_name } }, i) => {
                                    return (
                                        <Box key={i}>
                                            Cena sa popustom ({calc_name}):{" "}
                                            <span>
                                                {currencyFormat(discountMinPrice)} - {currencyFormat(discountMaxPrice)}
                                            </span>
                                        </Box>
                                    );
                                })}
                        </Box>
                    }
                >
                    <p
                        className={`w-100 p-2 mt-3 bold`}
                        style={{
                            textDecoration: "underline dotted",
                        }}
                    >
                        {currencyFormat(price?.min?.price?.discount)} - {currencyFormat(price?.max?.price?.discount)}
                    </p>
                </Tooltip>
            );
        }
    } else {
        const rebatePrice = calcRebatePrice(price?.price?.original, price?.rebate?.amount);
        const discountPrice = calcDiscountPrice(rebatePrice, price?.discount?.amount);

        return (
            <Tooltip
                placement={"top"}
                arrow={true}
                title={
                    <Box>
                        <Box>
                            Regularna cena: <span>{currencyFormat(price?.price?.original)}</span>
                        </Box>
                        {price?.rebate?.active &&
                            price?.rebate?.rebates?.map(({ calc: { calc_name } }, i) => {
                                return (
                                    <Box key={i}>
                                        Cena sa rabatom ({calc_name}): <span>{currencyFormat(rebatePrice)}</span>
                                    </Box>
                                );
                            })}
                        {price?.discount?.active &&
                            price?.discount?.campaigns?.map(({ calc: { calc_name } }, i) => {
                                return (
                                    <Box key={i}>
                                        Cena sa popustom ({calc_name}): <span>{currencyFormat(discountPrice)}</span>
                                    </Box>
                                );
                            })}
                    </Box>
                }
            >
                <p
                    className={`w-100 p-2 mt-3 bold`}
                    style={{
                        textDecoration: "underline dotted",
                    }}
                >
                    {currencyFormat(price?.price?.discount)}
                </p>
            </Tooltip>
        );
    }
};
