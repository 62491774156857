import { useNavigate, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { useState } from "react";

import MainCard from "../../components/layout/MainCard/MainCard";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";
import __ from "../../config/translations/serbian";
import useAPI from "../../hooks/useAPI";
import { GlobalQueries } from "../../hooks/useRefreshQuery";
import ProductItems from "../Catalogue/Products/ProductItems/ProductItems";
import EmptyPage from "../Empty/EmptyPage";

import scss from "./WishlistPage.module.scss";
import CatalogueAside from "pages/Catalogue/Aside/CatalogueAside";

/**
 * Show the user's wishlist.
 */
const WishlistPage = () => {
  const [activeCategory, setActiveCategory] = useState(null);
  const api = useAPI();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // Load all products in the cart
  const { data, isLoading } = useQuery([GlobalQueries.Wishlist], () => api.list(`/wishlist`));
  const items = data?.items.map((item) => ({ ...item, quantity: Math.floor(item.quantity) })) ?? [];

  // Show empty page
  if (!isLoading && items.length === 0) {
    return <EmptyPage slug={`wishlist-empty-modal`} message={__.EmptyPage.Templates.Wishlist} />;
  }



  const navigateToCategory = (category) => {
    navigate(pathname === "/pocetna" ? "/pocetna" : "/katalog/kategorija" + pathname === "/pocetna" ? "" : "/" + category.slug);
    setActiveCategory(category);
  };
  return (
    <MainCard>
      <CatalogueAside activeCategory={activeCategory} setActiveCategory={setActiveCategory} onActivateCategory={navigateToCategory} />
      <div className={scss.wrapper}>
        <LoadingWrapper isLoading={isLoading}>
          <ProductItems products={items} />
        </LoadingWrapper>
      </div>
    </MainCard>
  );
};

export default WishlistPage;
