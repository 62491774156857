import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import AddToCartButton from "../../../../../components/Form/AddToCartButton/AddToCartButton";
import FormWrapper, { preventDefault } from "../../../../../components/Form/FormWrapper";
import PlusMinusInput from "../../../../../components/Form/PlusMinusInput/PlusMinusInput";
import { currencyFormat } from "../../../../../helpers/functions";
import { missingImageURL } from "../../../../../helpers/images";
import useAPI from "../../../../../hooks/useAPI";
import Sitemap from "../../../../../routes/sitemap";

import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

import scss from "./ProductListItem.module.scss";
import { toast } from "react-toastify";
import { Prices } from "../../../../../components/prices";

const ProductListItem = ({ product }) => {
    const api = useAPI();
    const navigate = useNavigate();
    // Track the quantity to add to the cart
    const [quantity, setQuantity] = useState(1);

    const getQuantity = () => {
        if (quantity === 0) {
            setQuantity(1);
            return 1;
        }

        return quantity;
    };

    const [loading, setLoading] = useState(false);
    const [isPlusMinusInputEmpty, setIsPlusMinusInputEmpty] = useState(false);

    return (
        <>
            <Box sx={{ display: "flex" }} className={`${scss.wrapper} ${scss.wrapperBoxMobile}`}>
                <Link tabIndex={-1} to={Sitemap.catalogue.productDetails + "/" + product?.id} className={scss.wrapperLink}>
                    {/* Image and name */}
                    <img className={scss.image} src={product?.images[0] ?? missingImageURL()} alt={product?.name} />
                    <img className={scss.imageHover} src={product?.images[0] ?? missingImageURL()} alt={product?.name} />
                    <div className={scss.sku}>{product?.basic_data?.sku}</div>
                    <div className={scss.name}>{product?.basic_data?.name}</div>
                </Link>
                <div className={scss.data}>
                    <div className={scss.price}>
                        <Prices price={product?.price} inventory={product?.inventory} />{" "}
                    </div>
                    <FormWrapper
                        tabIndex={-1}
                        className={scss.addToCart}
                        onSubmit={() => {
                            if (isPlusMinusInputEmpty) {
                                toast.warn("Molimo unesite količinu!");
                                setLoading(false);
                            } else {
                                api.globalAddToCart(product.basic_data?.id_product, getQuantity(), loading, setLoading);
                                setQuantity(1);
                            }
                        }}
                    >
                        {product?.product_type === "variant" ? (
                            <div
                                onClick={() => {
                                    navigate(Sitemap.catalogue.productDetails + "/" + product?.id);
                                }}
                                className={`text-center w-auto   justify-content-center align-items-center  d-flex ${scss.button1}`}
                            >
                                Više opcija
                            </div>
                        ) : (
                            <>
                                {product?.price?.price_defined && product?.inventory.inventory_defined && product?.price?.price?.original > 0 ? (
                                    <>
                                        <div className={scss.plusminusinput}>
                                            <PlusMinusInput max={+product?.inventory?.amount} quantity={quantity} setQuantity={setQuantity} setIsPlusMinusInputEmpty={setIsPlusMinusInputEmpty} />
                                        </div>

                                        <AddToCartButton
                                            loading={loading}
                                            onClick={() => {
                                                if (isPlusMinusInputEmpty) {
                                                    toast.warn("Molimo unesite količinu!");
                                                    setLoading(false);
                                                } else {
                                                    api.globalAddToCart(product.basic_data?.id_product, getQuantity(), loading, setLoading);
                                                    setQuantity(1);
                                                }
                                            }}
                                            className={scss.addToCartButton}
                                            product={product}
                                        />
                                    </>
                                ) : (
                                    <div
                                        onClick={() => {
                                            navigate(`/kontakt?slug=${product?.slug}`);
                                        }}
                                        className={scss.upit}
                                    >
                                        Pošalji upit
                                    </div>
                                )}
                            </>
                        )}
                    </FormWrapper>
                </div>
            </Box>
            {/*<Link to={Sitemap.catalogue.productDetails + "/" + product?.id} className={scss.wrapperMobile}>*/}
            {/*    /!* Image and name *!/*/}
            {/*    <img className={scss.image} src={product?.images[0] ?? missingImageURL()} alt={product?.name} />*/}
            {/*    <img className={scss.imageHover} src={product?.images[0] ?? missingImageURL()} alt={product?.name} />*/}

            {/*    <div className={scss.data}>*/}
            {/*        <div className={scss.name}>{product?.basic_data?.name}</div>*/}
            {/*        <div className={scss.sku}>{product?.basic_data?.sku}</div>*/}
            {/*        <div className={scss.price}>*/}
            {/*            {renderPrices(product)} <PlusMinusInput quantity={quantity} setQuantity={setQuantity} className={scss.plusMinus} />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <FormWrapper className={scss.addToCart} onSubmit={() => api.globalAddToCart(product?.basic_data?.id_product, getQuantity(), loading, setLoading)}>*/}
            {/*        {product?.product_type === "variant" ? (*/}
            {/*            <p className={"text-center"}>Više opcija</p>*/}
            {/*        ) : product?.product_type === "variant" ? null : (*/}
            {/*            <AddToCartButton type={product?.inventory?.amount || product?.item?.inventory <= 0 ? "submit" : "button"} product={product} />*/}
            {/*        )}{" "}*/}
            {/*    </FormWrapper>*/}
            {/*</Link>*/}
        </>
    );
};

export default ProductListItem;
