import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import croonusLogo from "../../assets/images/logo/croonus-logo-menu-white.png";
import __ from "../../config/translations/serbian";
import Sitemap from "../../routes/sitemap";
import useAPI from "../../hooks/useAPI";
import AuthContext from "store/auth-context";
import { regax } from "helpers/const";
import useInput from "hooks/use-input";
import Input from "components/UI/Input";
import FrogotPasword from "components/ForgotPassword/ForgotPassword";

import scss from "./LoginPage.module.scss";

/**
 * The login page for the B2B.
 *
 * @return {JSX.Element}
 * @constructor
 */
const LoginPage = () => {
    const api = useAPI();
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const {
        value: emailValue,
        isValid: emailIsValid,
        hasError: emailHasError,
        valueChangeHandler: emailChangeHandler,
        inputBlurHandler: emailBlurHandler,
    } = useInput((value) => (!value && value.trim() !== "") || regax.test(value) !== false);

    const {
        value: passwordValue,
        isValid: passwordIsValid,
        hasError: passwordHasError,
        valueChangeHandler: passwordChangeHandler,
        inputBlurHandler: passwordBlurHandler,
    } = useInput((value) => value.trim() !== "");

    const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);

    /** Handle form submitting. */
    const submitHandler = async (event) => {
        event.preventDefault();

        // Guard: supplied values are valid
        if (!(emailIsValid && passwordIsValid)) {
            toast.warning(__.Login.Errors.Form);
            return;
        }

        // Prepare the data
        const loginData = {
            email: emailValue,
            password: passwordValue,
        };

        // Execute the API
        api.post("/customer/login", loginData)
            .then((response) => {
                login(response);
                localStorage.setItem("currency", response?.price_market?.currency);
                navigate("/pocetna");
            })
            .catch((error) => {
                console.error(error);
                toast.error(__.Login.Errors.TryAgain);
            });
    };

    // The title, subtitle and the description
    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [description, setDescription] = useState("");
    useEffect(() => {
        api.get("/configuration/presentation/login/title/value", false).then((response) => setTitle(response));
        api.get("/configuration/presentation/login/subtitle/value", false).then((response) => setSubtitle(response));
        api.get("/configuration/presentation/login/description/value", false).then((response) => setDescription(response));
    }, [api]);

    return (
        <>
            <div className={scss["login-container"]}>
                <div className={scss["login-card"]}>
                    <div className={scss["background-wrapper"]}>
                        <img src={api.buildPath("/configuration/images/presentation/login/image")} alt="" />
                        <div />
                    </div>
                    <div className={scss["login-card-gray-overlay"]}>
                        {/* Background image */}
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                            className={scss.logoHolder}
                        >
                            <img
                                src={api.buildPath("/configuration/images/presentation/login/logo")}
                                className={scss["logo"]}
                                alt="logo"
                                style={{
                                    position: "relative",
                                }}
                            />
                            <p
                                className={scss.logoText}
                                style={{
                                    color: "white",
                                    fontSize: "1.5rem",
                                    position: "relative",
                                    fontWeight: "bolder",
                                    textAlign: "left",
                                    //tight fit
                                    lineHeight: "0.85",
                                    marginTop: "1rem",
                                    marginLeft: "2.5rem",
                                }}
                            >
                                B2B
                                <br /> PORTAL
                            </p>
                        </div>

                        {/* Title, subtitle and description */}
                        <h5 className={scss["login-sub-title"]}>{subtitle}</h5>
                        <h1 className={scss["company-title"]}>
                            {title}
                            {/*<span className={scss["big-dot"]}>.</span>*/}
                        </h1>
                        <h5 className={scss["login-sub-title"]}>{description}</h5>

                        <form onSubmit={submitHandler} className={scss["form"] + " col-xxl-4 col-lg-6 login-form-style"}>
                            <div className={scss["input-container"] + " form-floating mb-3"}>
                                <Input
                                    inputValue={emailValue}
                                    onInputChange={emailChangeHandler}
                                    onInputBlur={emailBlurHandler}
                                    hasInputError={emailHasError}
                                    inputType="input"
                                    class={scss["input"] + " form-control"}
                                    inputErrorText={__.Login.Errors.Email}
                                />

                                <label className={scss["label"]} htmlFor="email">
                                    {__.Login.UsernameLabel}
                                </label>
                            </div>
                            <div className={scss["input-container"] + " form-floating mb-3"}>
                                <Input
                                    inputValue={passwordValue}
                                    onInputChange={passwordChangeHandler}
                                    onInputBlur={passwordBlurHandler}
                                    hasInputError={passwordHasError}
                                    inputType="input"
                                    type="password"
                                    class={scss["input"] + " form-control"}
                                    inputErrorText={__.Login.Errors.Password}
                                />

                                <label className={scss["label"]} htmlFor="password">
                                    {__.Login.PasswordLabel}
                                </label>
                            </div>
                            <div className={scss.forgotPassword}>
                                <p className="clickable" onClick={() => setForgotPasswordDialog(true)}>
                                    Zaboravili ste lozinku?
                                </p>
                            </div>
                            <div className={scss["login-submit-container"]}>
                                <button type="submit" className={scss["login-submit"]}>
                                    {__.Login.LoginButton}
                                </button>
                            </div>
                        </form>
                        <div className={`${scss.register} col-xxl-4 col-lg-6`}>
                            <span>Nemate pristupne podatke?</span>
                            <span>
                                <a href={Sitemap.auth.register}>Postanite B2B korisnik</a>
                            </span>
                        </div>

                        {/* Copyright */}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "start",
                                marginRight: "auto",
                            }}
                            className={scss["powered"] + " col-xxl-4 col-lg-6"}
                        >
                            <p className={scss["paragraph"]}>Powered by: </p>
                            <a href="https://croonus.com/" target="_blank" rel="noopener noreferrer">
                                <img src={croonusLogo} width={150} alt="Croonus logo" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <FrogotPasword openDialog={forgotPasswordDialog} setOpenDialog={setForgotPasswordDialog} enteredEmail={emailValue} />
        </>
    );
};

export default LoginPage;
