import { checkIsInStock, checkPrices, getPriceStatus, renderDefaultPrices, renderDiscountPrices, renderRebateAndDiscountPrices, renderRebatePrices } from "./functions";

export const Prices = ({ price, inventory }) => {
    let status = getPriceStatus(price);
    let is_in_stock = checkIsInStock(inventory);
    let prices = checkPrices(price);

    let data = {
        status: status,
        is_in_stock: is_in_stock,
        price_defined: prices?.price_defined,
        is_price_range: prices?.price_range,
        price: price,
    };

    if (!data?.is_in_stock || !data.price_defined) {
        return <p className={`w-100 text-black p-2 rounded-3 mt-3 `}>Pošaljite upit</p>;
    }

    switch (data?.status) {
        case "default":
            return renderDefaultPrices({ ...data });
        case "discount":
            return renderDiscountPrices({ ...data });
        case "rebates":
            return renderRebatePrices({ ...data });
        case "discount_rebates":
            return renderRebateAndDiscountPrices({ ...data });
    }
};
