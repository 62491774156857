export const useBannerFunctions = () => {
    const RenderImage = (height, width, image) => {
        return <img src={image} alt={``} width={width} height={height} sizes={`100vw`} style={{ minHeight: height, maxHeight: height, minWidth: "100%", width: "100%", objectFit: "fill" }} />;
    };

    const RenderVideo = (height, image) => {
        return (
            <video autoPlay loop muted playsInline className={`!w-full`} style={{ minHeight: height, maxHeight: height, objectFit: "fill", width: "100%" }}>
                <source src={image} type="video/mp4" />
            </video>
        );
    };

    const RenderVideoURL = (height, src) => {
        return (
            <iframe
                src={src}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className="!h-full !w-full"
                style={{ minHeight: height, maxHeight: height, objectFit: "fill", width: "100%" }}
            />
        );
    };

    const HandleRender = ({ type, image, height, width, src }) => {
        switch (type) {
            case "image":
                return RenderImage(height, width, image);
            case "video":
                return RenderVideo(height, image);
            case "video_link":
                return RenderVideoURL(height, src);
        }
    };

    return {
        HandleRender,
    };
};
