import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import Button from "../../../components/Buttons/Button/Button";
import { join } from "../../../helpers/functions";
import useAPI from "../../../hooks/useAPI";
import scss from "./Banners.module.scss";
import { useBannerFunctions } from "../../../hooks/useBannerFunctions";

/**
 * Banner components, two horizontally placed banners
 */
const Banners = () => {
    const api = useAPI();
    const { HandleRender } = useBannerFunctions();

    const convertToEmbed = (video_url, video_provider) => {
        const getYouTubeVideoId = (url) => {
            if (!url) return null;

            const splitByWatch = url.split("v=");
            if (splitByWatch.length > 1) {
                return splitByWatch[1].split("&")[0];
            } else {
                const splitBySlash = url.split("/");
                return splitBySlash[splitBySlash.length - 1];
            }
        };

        const getVimeoVideoId = (url) => {
            if (!url) return null;

            const splitBySlash = url.split("/");
            return splitBySlash[splitBySlash.length - 1];
        };

        let embedUrl = "";
        let video_id;
        if (video_provider === "youtube") {
            video_id = getYouTubeVideoId(video_url);
            if (video_id) {
                embedUrl = `https://www.youtube.com/embed/${video_id}`;
            }
        } else if (video_provider === "vimeo") {
            video_id = getVimeoVideoId(video_url);
            if (video_id) {
                embedUrl = `https://player.vimeo.com/video/${video_id}`;
            }
        }

        return embedUrl;
    };

    // Load primary banners
    const { data: primary } = useQuery(["Banners.primary"], () => api.list(`/banners/primary`));
    const primaryBanners = primary?.items ?? [];

    // Load secondary banners
    const { data: secondary } = useQuery(["Banners.secondary"], () => api.list(`/banners/secondary`));
    const secondaryBanners = secondary?.items ?? [];

    // Load secondary banners
    const { data: mobile } = useQuery(["Banners.mobile"], () => api.list(`/banners/mobile_slider`));
    const mobileBanners = mobile?.items ?? [];

    // Holds which banners are currently displayed
    const [primaryDisplayed, setPrimaryDisplayed] = useState(0);
    const [secondaryDisplayed, setSecondaryDisplayed] = useState(0);
    const [mobileDisplayed, setMobileDisplayed] = useState(0);

    // There are no banners to show
    if (primaryBanners?.length + secondaryBanners?.length === 0) {
        return null;
    }

    // Get the class
    const className = (banner, index) => join([scss[index === primaryDisplayed ? "banner-active" : "banner"], banner.url && "clickable"]);

    // Handle clicks
    const onClick = (banner) => {
        if (banner.url) {
            if (banner.target === "blank") {
                window.open(banner.url, "_blank");
                return;
            }

            window.open(banner.url, "_self");
        }
    };

    return (
        <>
            <div className={`${scss.wrapper} mobile-hidden`}>
                {/* Primary */}
                <div className={scss.primary}>
                    {primaryBanners.map((banner, index) => (
                        <div key={banner.id} className={className(banner, index)} onClick={() => onClick(banner)}>
                            {/* Render the image */}
                            {HandleRender({
                                type: banner?.type,
                                width: banner?.width,
                                height: banner?.height,
                                image: banner?.image,
                                src: banner?.type === "video_link" ? convertToEmbed(banner?.video_url, banner?.video_provider) : null,
                            })}

                            {/* Texts and actions */}
                            {(banner.title || banner.subtitle || banner.text || banner.button) && (
                                <div className={scss.texts}>
                                    {/* Title and subtitle */}
                                    {banner.title && <h3>{banner.title}</h3>}
                                    {banner.subtitle && <h4>{banner.subtitle}</h4>}

                                    {/* Text */}
                                    {banner.text && <p>{banner.text}</p>}

                                    {/* Optional button */}
                                    {banner.button && <Button label={banner.button} className="mt-3" onClick={() => onClick(banner)} />}
                                </div>
                            )}
                        </div>
                    ))}
                    {primaryBanners.length > 1 && (
                        <div className={scss.dots}>
                            {primaryBanners.map((banner, index) => {
                                return (
                                    <span
                                        key={banner.id}
                                        className={`${scss.dot} ${index === primaryDisplayed && scss.dotActive} clickable`}
                                        onClick={() => {
                                            setPrimaryDisplayed(index);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>

                {/* Secondary */}
                <div className={scss.secondary}>
                    {secondaryBanners.map((banner, index) => (
                        <div key={banner.id} className={`${scss[index === secondaryDisplayed ? "banner-active" : "banner"]} ${banner.url && "clickable"}`} onClick={() => onClick(banner)}>
                            {/* Render the image */}
                            {HandleRender({
                                type: banner?.type,
                                width: banner?.width,
                                height: banner?.height,
                                image: banner?.image,
                                video_provider: banner?.video_provider,
                                video_url: banner?.video_url,
                                src: banner?.type === "video_link" ? convertToEmbed(banner?.video_url, banner?.video_provider) : null,
                            })}

                            {/* Texts and actions */}
                            {(banner.title || banner.subtitle || banner.text || banner.button) && (
                                <div className={scss.texts}>
                                    {/* Title and subtitle */}
                                    {banner.title && <h3>{banner.title}</h3>}
                                    {banner.subtitle && <h4>{banner.subtitle}</h4>}

                                    {/* Text */}
                                    {banner.text && <p>{banner.text}</p>}

                                    {/* Optional button */}
                                    {banner.button && (
                                        <a className={scss.button} target={banner.target} href={banner.url}>
                                            {banner.button}
                                        </a>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                    {secondaryBanners.length > 1 && (
                        <div className={scss.dots}>
                            {secondaryBanners.map((banner, index) => {
                                return (
                                    <span
                                        key={banner.id}
                                        className={`${scss.dot} ${index === secondaryDisplayed && scss.dotActive} clickable`}
                                        onClick={() => {
                                            setSecondaryDisplayed(index);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
            <div className={`${scss.mobileWrapper} desktop-hidden`}>
                <div className={scss.mobile}>
                    {mobileBanners.map((banner, index) => (
                        <div key={banner.id} className={scss[index === mobileDisplayed ? "banner-active" : "banner"]}>
                            {/* Render the image */}
                            {HandleRender({
                                type: banner?.type,
                                width: banner?.width,
                                height: banner?.height,
                                image: banner?.image,
                                video_provider: banner?.video_provider,
                                video_url: banner?.video_url,
                                src: banner?.type === "video_link" ? convertToEmbed(banner?.video_url, banner?.video_provider) : null,
                            })}
                        </div>
                    ))}
                    {mobileBanners.length > 1 && (
                        <div className={scss.dots}>
                            {mobileBanners.map((banner, index) => {
                                return (
                                    <span
                                        key={banner.id}
                                        className={`${scss.dot} ${index === mobileDisplayed && scss.dotActive} clickable`}
                                        onClick={() => {
                                            setMobileDisplayed(index);
                                        }}
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Banners;
